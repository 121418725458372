import styled from '@emotion/styled';
import { Container } from 'layouts';

const OrgaSectionWrapper = styled.section`
  padding: 2em 0 0em;
  min-height: 100vh;
  position: relative;
  @media (max-width: 1060px) {
    display: none;
  }
`
const OrgaHeaderCenter = styled.div`
text-align: center;
display: block ruby;
`

const OrgaHeaderWrapper = styled.div`
  display: inline-block;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  color: #fff;
  background: #d0421a;
  width: 1010px;
  margin: 0 0;
  box-shadow: 5px 5px 9px grey;
`
const OrgaDivWrapper = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto 3em;
`

const OrgaItemWrapper = styled.div`
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0.5em;
  padding: 2em 1.5em;
  text-align: left;
  color: #fff;
  background: #114f72;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  text-align: center;
  flex: 0 1 394px;
  box-shadow: 5px 5px 9px grey;
`
const OrgaVerticalWrapper = styled(OrgaItemWrapper)`
writing-mode: vertical-lr;
flex: 0;
background: #4b5962;
`
const OrgaListWrapper = styled.ul`
  font-size: 0.95em;
  margin: 0;
  padding: 0 0 2.5em;
  list-style: none;
  color: #fff;
  text-align: left;
`

const Orga = ({}) => {
    return (<OrgaSectionWrapper>
        <Container>
            <h1 id="orga">Organisation</h1>
        </Container>
        <OrgaHeaderCenter>
            <OrgaHeaderWrapper>
                <h3 style={{ marginTop: "25px" }}>Plateforme de Bioinformatique et Big data au Service de la Santé</h3>
                <p>Direction scientifique - Pr Didier Hocquet (UMR 6249)</p>
            </OrgaHeaderWrapper>
        </OrgaHeaderCenter>

        <OrgaDivWrapper>
            <OrgaItemWrapper>
                <h3>Comité technique</h3>
                <OrgaListWrapper>
                    <li>Dr. Benoit Valot <i>IR - UMR 6249 (Supervision technique)</i></li>
                    <li>Anaghim Temtem <i>IE - UFR Sciences de la Santé</i></li>
                    <li>Dossi Carine Gnide <i>IE - CHU de Besançon</i></li>
                    <li>Ruben Ladeira <i>IE - CHU de Besançon</i></li>
                    <li>Babacar Nado <i>IE - UMR 1098</i></li>
                    <li>Dr. Julien Viot <i>UMR 1098</i></li>
                </OrgaListWrapper>
            </OrgaItemWrapper>
            <OrgaItemWrapper>
                <h3>Comité de pilotage scientifique</h3>
                <OrgaListWrapper>
                    <li>Dr. Benoit Valot - <i>UMR 6249</i></li>
                    <li>Pr. Emilie Gauthier  - <i>UMR 6249</i></li>
                    <li>Pr. Olivier Adotevi  - <i>UMR 1098</i></li>
                    <li>Pr. Siamak Davani  - <i>UMR 3920</i></li>
                    <li>Pr. Frédéric Lirussi  - <i>UMR 1231</i></li>
                    <li>Pr Frédéric Auber - <i>EA 4662</i></li>
                    <li>Pr. Laurence Millon  - <i>UMR 6249, CNR Echinococcose alvéolaire</i></li>
                    <li>Pr. Katy Jeannot  - <i>UMR 6249</i></li>
                    <li>Pr Jean-Luc Prétet - <i>EA 3181, CNR Papillomavirus</i></li>
                    <li>Pr. Didier Hocquet - <i>UMR 6249/UF 7790 Bioinformatique</i></li>
                    <li>Pr. Jean-Paul Feugeas - <i>Oncobiologie, Génétique, Bioinformatique au CHU de Besançon</i></li>
                    <li>Pr. Paul Kuentz - <i>Oncobiologie, Génétique, Bioinformatique au CHU de Besançon</i></li>
                    <li>Dr. Alexis Overs - <i>Oncobiologie, Génétique, Bioinformatique au CHU de Besançon</i></li>
                    <li>Dr. Zohair Selmani - <i>Oncobiologie, Génétique, Bioinformatique au CHU de Besançon</i></li>
                </OrgaListWrapper>
            </OrgaItemWrapper>
            <OrgaVerticalWrapper style={{ width: "73px" }}>
                <p>Système de management de la qualité</p>
            </OrgaVerticalWrapper>
            <OrgaVerticalWrapper style={{ width: "110px" }}>
                <p>Maintenance parc informatique</p>
                <p>Équipe technique UFR Santé</p>
            </OrgaVerticalWrapper>
        </OrgaDivWrapper>
    </OrgaSectionWrapper>);
};

export default Orga;
