import TagsBlock from "./TagsBlock";

const ShapeTitle = props => {
	return (
		<text x={24} y={-10} fontSize="0.9rem" fontFamily="LoveloBlack" stroke="none" fill="#f5f3e7" fontWeight={100} style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>{props.children}</text>
	)
}

const ShapeText = props => {
	return (
		<text x={props.x} y={props.y} fontSize="0.8rem" fontFamily="Roboto Condensed, sans-serif" stroke="none" fill="#050307" fontWeight={50} style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
			{props.children}
		</text>
	)
}

const ShapeCircle = ({ cx, cy, r, title, t1, t2 }) => {
	return (
		<g transform={"translate(" + (300 - r) + ", " + cy + ")"}>
			<circle fill="#d0421a" cx={cx} cy={0} r={r} opacity={1} filter="url(#shadow)" />
			<ShapeTitle>{title}</ShapeTitle>
			<ShapeText x={cx - r + 25} y={15}>{t1}</ShapeText>
			<ShapeText x={cx - r + 30} y={29}>{t2}</ShapeText>
		</g>
	)

}

const ShapeRectancgle = props => {
	return (
		<g transform={"translate(" + (props.center ? (600 / 2 - props.width / 2) : props.tx) + ", " + props.ty + ")"}>
			<rect fill={props.final ? "#d0421a" : "#114f72"} rx={3} ry={3} width={props.width+20} height={props.height} filter="url(#shadow)" />
			<text x={15} y={20} fontFamily="LoveloBlack" fontSize="0.8rem" stroke="none" fill="#f5f3e7" fontWeight={900} style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
				{props.children}
			</text>
		</g>
	)
}

const ShapeSub = props => {
	return (
		<tspan fontFamily="Roboto Condensed, sans-serif" fontWeight={400} x={15} dy={20} fontSize="0.7rem" fill="#050307">
			{props.children}
		</tspan>
	)
}
const RequestChart = ({ }) => {
	return (
		<div className="wrapper">
			<div className="svg-container">
				<svg version="1.1" viewBox="0 0 600 550" preserveAspectRatio="xMinYMin meet" className="svg-content">
					<defs>
						<marker id="arrow" markerWidth={4} markerHeight={10} viewBox="-2 -4 4 4" refX={0} refY={0} markerUnits="strokeWidth" orient="auto">
							<polyline points="2,-2 0,0 2,2" stroke="#443c3d" strokeWidth="0.75px" fill="none" />
						</marker>
						<filter id="shadow" x="0" y="0" width="200%" height="200%">
							<feOffset result="offOut" in="SourceGraphic" dx="3" dy="3" />
							<feColorMatrix result="matrixOut" in="offOut" type="matrix"
								values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0" />
							<feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="2" />
							<feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
						</filter>
					</defs>
					<g className="box-group">
                    <ShapeCircle cx={60} cy={58} r={58} title="Demandeur" t1="soumission" t2="du projet" />
						<line x1={305} x2={305} y1={118} y2={150} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<ShapeRectancgle tx={180} ty={150} width={155} height={50} center={true}>
							comité technique
							<ShapeSub>Avis préalable et visa</ShapeSub>
						</ShapeRectancgle>
						<line x1={305} x2={305} y1={200} y2={235} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<ShapeRectancgle tx={180} ty={235} width={310} height={75} center={true}>
							comité de pilotage
							<ShapeSub>évaluation technique et scientifique du projet</ShapeSub>
							<ShapeSub>choix du mode de réalisation</ShapeSub>
						</ShapeRectancgle>
						<line x1={305} x2={305} y1={310} y2={330} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<line x1={205} x2={405} y1={330} y2={330} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<line x1={205} x2={205} y1={330} y2={350} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<line x1={405} x2={405} y1={330} y2={350} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<ShapeRectancgle tx={78} ty={350} width={210} height={30}>
							Collaboration académique
						</ShapeRectancgle>
						<ShapeRectancgle tx={320} ty={350} width={200} height={30}>
							Prestation industrielle
						</ShapeRectancgle>
						<line x1={405} x2={405} y1={380} y2={400} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<ShapeRectancgle tx={360} ty={400} width={90} height={50}>
							Saic
							<ShapeSub>Facturation</ShapeSub>
						</ShapeRectancgle>
						<line x1={205} x2={405} y1={465} y2={465} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<line x1={305} x2={305} y1={465} y2={480} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<line x1={405} x2={405} y1={450} y2={465} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<line x1={205} x2={205} y1={380} y2={465} strokeWidth={2} stroke="#443c3d" strokeDasharray="2,1" />
						<ShapeRectancgle tx={180} ty={480} width={305} height={50} center={true} final={true}>
							Bio-informaticiens de l'axe concerné
							<ShapeSub>Réalisation du projet et livraison</ShapeSub>
						</ShapeRectancgle>
					</g>
				</svg>
			</div>
		</div>);
};

export default RequestChart;